/**
* Generated automatically at built-time (2024-08-02T08:11:00.850Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "mquvee-push-pull-wine-cooler",templateKey: "sites/71-05a8a295-733e-4d1f-8416-d3b0b125387d"};